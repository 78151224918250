import React, { useState, useEffect } from "react";
import { KnockService, PostService, CategoriesService } from "../../../services";
import { getSimilarity } from "../../../services";

const RandomKnocksAndPosts: React.FC = () => {
  const [randomKnocks, setRandomKnocks] = useState<any[]>([]);
  const [randomPosts, setRandomPosts] = useState<any[]>([]);
  const [selectedPost, setSelectedPost] = useState<any>(null);
  const [inputText, setInputText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPosts, setLoadingPosts] = useState<boolean>(false);
  const [loadingKnocks, setLoadingKnocks] = useState<boolean>(false);
  const [similarityScores, setSimilarityScores] = useState<number[]>([]);
  const [activeCategories, setActiveCategories] = useState<{ id: string; name: string }[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("");

  const handleGetRandomKnock = async () => {
    if (selectedPost) {
      setLoadingKnocks(true);
      const knock = await KnockService.getRandomKnocks(1, selectedPost.categoryId);
      if (knock && knock.length > 0) {
        const similarityScore = await getSimilarity({
          description: knock[0].description,
          postId: selectedPost.id
        });
        setRandomKnocks((prevKnocks) => [knock[0], ...prevKnocks]);
        setSimilarityScores((prevScores) => [similarityScore, ...prevScores]);
      }
      setLoadingKnocks(false);
    }
  };

  const handleGetRandomPosts = async () => {
    if (!selectedCategory) return;

    setLoadingPosts(true);
    const posts = await PostService.getRandomPosts(5, selectedCategory);
    setRandomPosts(posts);
    setLoadingPosts(false);
  };

  const handleSelectPost = (post: any) => {
    if (selectedPost === post) {
      setSelectedPost(null);
      setRandomKnocks([]);
      setSimilarityScores([]);
    } else {
      setSelectedPost(post);
      setRandomKnocks([]);
      setSimilarityScores([]);
    }
  };

  const handleCustomKnockSubmit = async () => {
    if (!inputText || !selectedPost) return;
    setLoading(true);
    const customKnock = {
      customerName: "Admin",
      description: inputText,
    };
    const similarityScore = await getSimilarity({
      description: customKnock.description,
      postId: selectedPost.id
    });
    setRandomKnocks((prevKnocks) => [customKnock, ...prevKnocks]);
    setSimilarityScores((prevScores) => [similarityScore, ...prevScores]);
    setInputText("");
    setLoading(false);
  };

  useEffect(() => {
    const fetchActiveCategories = async () => {
      const categories = await CategoriesService.getActiveCategories();
      setActiveCategories(categories);

      // select random category
      if (categories.length > 0) {
        const randomCategory = categories[Math.floor(Math.random() * categories.length)];
        setSelectedCategory(randomCategory.id);
      }
    };

    fetchActiveCategories();

    return () => {
      setActiveCategories([]);
      setSelectedCategory("");
    };
  }, []);

  return (
    <div className="p-6">
      <div className="flex items-center mb-4">
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          className="p-2 border rounded-lg mr-4"
        >
          {activeCategories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
        <button
          onClick={handleGetRandomPosts}
          className={`bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition ${
            loadingPosts ? "cursor-not-allowed opacity-50" : ""
          }`}
          disabled={loadingPosts}
        >
          {loadingPosts ? "Getting 5 Random Posts..." : "Get 5 Random Posts"}
        </button>
      </div>
      <div className="flex justify-around mb-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4">Random Posts</h3>
            {randomPosts.length > 0 ? (
              randomPosts.map((post, index) => (
                <div
                  key={index}
                  className={`mb-4 p-4 rounded-lg flex items-center cursor-pointer ${
                    selectedPost === post ? "bg-blue-100" : "bg-gray-100"
                  }`}
                  onClick={() => handleSelectPost(post)}
                >
                  <div>
                    <p className="font-semibold">Business Name: {post.businessName}</p>
                    <p>Description: {post.description}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No posts loaded yet.</p>
            )}
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md">
            {selectedPost && (
              <>
                <button
                  onClick={handleGetRandomKnock}
                  className={`bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition mb-4 ${
                    loadingKnocks ? "cursor-not-allowed opacity-50" : ""
                  }`}
                  disabled={loadingKnocks}
                >
                  {loadingKnocks ? "Getting Knock and Calculating Similarity..." : "Get Random Knock"}
                </button>
                <div className="mb-4">
                  <input
                    type="text"
                    placeholder="Write your own knock"
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    className="p-2 border rounded-lg w-full"
                  />
                  <button
                    onClick={handleCustomKnockSubmit}
                    className={`bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition mt-2 ${
                      loading ? "cursor-not-allowed opacity-50" : ""
                    }`}
                    disabled={loading}
                  >
                    {loading ? "Submitting Knock..." : "Submit Knock"}
                  </button>
                </div>
              </>
            )}
            {randomKnocks.length > 0 && (
              <div className="bg-white p-4 rounded-lg shadow-md w-full mt-6">
                <h3 className="text-xl font-semibold mb-4">Knocks Available</h3>
                {randomKnocks.map((knock, index) => (
                  <div key={index} className="mb-4 p-4 bg-gray-100 rounded-lg">
                    <p> <strong>Customer Name</strong>: {knock.customerName}</p>
                    <p> <strong>Description</strong>: {knock.description}</p>
                    <p className="text-blue-600 font-bold">Similarity Score: {similarityScores[index].toFixed(3)}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RandomKnocksAndPosts;
