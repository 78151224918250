import React, { useState } from "react";
import { generateQuestions, generateRequestEnhanced } from "../../../services"; // Adjust the import path if needed
import { AIQuestionData, RequestEnhancedPayload, RequestEnhancedResponse } from "../../../types"; // Adjust the import path if needed

const QuestionGeneratorDemo: React.FC = () => {
  const [questionData, setQuestionData] = useState<AIQuestionData | null>(null); // Holds the generated questions data
  const [inputText, setInputText] = useState<string>(""); // Holds the user's input
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  const [error, setError] = useState<string | null>(null); // Error handling
  const [answers, setAnswers] = useState<{ [key: string]: string }>({}); // Holds the selected answers
  const [enhancedResponse, setEnhancedResponse] = useState<RequestEnhancedResponse | null>(null); // Holds the enhanced response
  const [inputError, setInputError] = useState<string | null>(null); // Error for input validation
  const [answerError, setAnswerError] = useState<string | null>(null); // Error for answer validation

  const handleGenerateQuestion = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setInputError(null);
    setEnhancedResponse(null); // Reset the enhanced response when generating new questions

    if (inputText.trim().length < 8) {
      setInputError("The request should be at least 8 characters long.");
      return;
    }

    setLoading(true); // Start loading
    setError(null); // Reset error state

    try {
      const response = await generateQuestions(inputText); // Send input to the API
      setQuestionData(response); // Set the full question data response
      setAnswers({}); // Reset selected answers when new questions are generated
    } catch (err) {
      console.error("Error generating question:", err);
      setError("Something went wrong. Please try again.");
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleAnswerSelect = (questionKey: string, answer: string) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionKey]: answer,
    }));
    setAnswerError(null); // Reset answer error when a question is answered
  };

  const handleSubmitAnswers = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault(); // Prevent default form submission behavior
      setAnswerError(null); // Reset answer error

      if (questionData === null) return; // Ensure that questionData is not null

      // Filter out only the keys that are actual questions
      const questionKeys = Object.keys(questionData).filter(
          (key) => key.startsWith("Question")
      );

      // Check if all questions have been answered
      if (questionKeys.some((key) => answers[key] === undefined)) {
          setAnswerError("You must respond to all the questions to generate the enhanced request.");
          return;
      }

      const formattedAnswers = questionKeys.reduce((acc: Array<{ question: string, answer: string }>, questionKey) => {
          const question = questionData[questionKey as keyof AIQuestionData];

          // Ensure that the key corresponds to a valid question and it has a corresponding answer
          if (
              question && 
              typeof question === 'object' && 
              'question' in question && 
              question.question &&
              answers[questionKey] !== undefined
          ) {
              acc.push({ question: question.question, answer: answers[questionKey] });
          }

          return acc;
      }, []);

      const payload: RequestEnhancedPayload = {
          initialRequest: inputText,  // Include the initial request the user made
          responseDetails: formattedAnswers,  // Include the formatted questions and answers
      };

      console.log("Payload to be sent:", payload); // Log the payload to be sent to the API

      setLoading(true); // Start loading

      try {
          const response: RequestEnhancedResponse = await generateRequestEnhanced(payload); // Send the payload to the enhanced request API
          console.log("Enhanced Request Response:", response); // Log the response from the enhanced request API
          setEnhancedResponse(response); // Store the response to display it
      } catch (err) {
          console.error("Error in generateRequestEnhanced:", err);
          setError("Something went wrong with the enhanced request. Please try again.");
      } finally {
          setLoading(false); // End loading
      }
  };


  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4 text-center">
        Tell Us About the Problem
      </h2>

      <form onSubmit={handleGenerateQuestion}>
        <textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="What's the issue you need help with?"
          className={`w-full p-3 border rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500 ${inputError ? 'border-red-500' : ''}`}
        />
        {inputError && <p className="text-red-500 mt-1">{inputError}</p>}

        <button
          type="submit"
          className={`w-full bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 transition ${
            loading ? 'cursor-not-allowed' : ''
          }`}
          disabled={loading}
        >
          {loading ? "Finding Questions..." : "Next"}
        </button>
      </form>

      {error && <p className="text-red-500 mt-4">{error}</p>}

      {questionData && questionData.isGood === false ? (
        <div className="mt-6">
          <h3 className="text-xl font-semibold mb-2">Assistant:</h3>
          <p className="bg-gray-100 p-4 rounded-lg">{questionData.originalResponse.answer}</p>
        </div>
      ) : (
        questionData && (
          <form onSubmit={handleSubmitAnswers} className="mt-6">
            <h3 className="text-xl font-semibold mb-2">We just need a little more info:</h3>

            {Object.keys(questionData).map((questionKey, index) => {
              const question = questionData[questionKey as keyof AIQuestionData];

              // Ensure that the key corresponds to a question and is of type QuestionResponse
              if (question && typeof question === 'object' && 'question' in question && 'answers' in question && question.question) {
                return (
                  <div key={index} className="bg-gray-100 p-4 mb-4 rounded-lg">
                    <p className="font-semibold">{question.question}</p>
                    <div className="mt-2">
                      {question.answers.map((answer: string, answerIndex: number) => (
                        <button
                          key={answerIndex}
                          type="button"
                          className={`mr-2 mb-2 py-2 px-4 rounded-lg focus:outline-none ${
                            answers[questionKey] === answer
                              ? "bg-blue-500 text-white"
                              : "bg-gray-200 text-black"
                          }`}
                          onClick={() => handleAnswerSelect(questionKey, answer)}
                        >
                          {answer}
                        </button>
                      ))}
                    </div>
                  </div>
                );
              }

              return null; 
            })}

            {answerError && <p className="text-red-500 mt-1">{answerError}</p>}

            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mt-4"
              disabled={loading} // Disable if loading
            >
              {loading ? "Submitting..." : "Send Your Answers"}
            </button>
          </form>
        )
      )}

      {/* Display the summary and inferred category if available */}
      {enhancedResponse && (
        <div className="mt-6 bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Request Enhanced</h3>
          <p className="text-lg"><span className="font-semibold">Summary:</span> {enhancedResponse.summary}</p>
          <p className="text-lg"><span className="font-semibold">Category:</span> {enhancedResponse.categoryName}</p>
        </div>
      )}
    </div>
  );
};

export default QuestionGeneratorDemo;
