import QuestionGeneratorDemo from "../../../components/ai/Demos/FollowUpQuestionTest";
import SimilarityScoreTest from "../../../components/ai/Demos/SimilarityScoreTest";
import { PageHeader } from "../../../components/shared";

type AssistantDemoProps = {
  title: string;
};

export const AssistantDemo = ({ title }: AssistantDemoProps) => {
  return (
    <>
      <PageHeader>{title}</PageHeader>
      <div className="mt-8 flex flex-col space-y-4 md:space-y-0 md:flex-col lg:flex-row lg:space-x-4">
        <div className="flex-1 p-6 bg-white rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-4">Question Generator</h3>
          <QuestionGeneratorDemo />
        </div>

        <div className="flex-1 p-6 bg-white rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-4">Similarity Score</h3>
          <SimilarityScoreTest />
        </div>
      </div>
    </>
  );
};
